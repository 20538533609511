<template>
  <b-overlay :show="$apollo.loading">
    <b-card>
      <!-- form -->
      <b-form @submit.prevent="updateUser">
        <b-row>
          <b-col sm="6">
            <b-form-group
              label="Name"
              label-for="account-username"
            >
              <b-form-input
                v-model="optionsLocal.first_name"
                readonly
                placeholder="Name"
                name="username"
              />
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group
              label="E-mail"
              label-for="account-e-mail"
            >
              <b-form-input
                v-model="userEmail"
                readonly
                name="email"
                placeholder="Email"
              />

            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group
              label="Phone Number"
              label-for="account-phone-number"
            >
              <b-form-input
                v-model="optionsLocal.phone_number"
                name="phone"
                placeholder="Phone Number"
              />
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group
              label="Facebook Profile"
              label-for="account-facebook"
            >
              <b-form-input
                v-model="optionsLocal.facebook_profile"
                name="facebook"
                placeholder="https://facebook.com/yourprofile"
              />
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group
              label="Linkedin Profile"
              label-for="account-linkedin"
            >
              <b-form-input
                v-model="optionsLocal.linkedin_profile"
                name="linkedin"
                placeholder="https://linkedin.com/in/yourprofile"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              :disabled="loading"
              type="submit"
              variant="primary"
              class="mt-2 mr-1 d-flex align-items-center"
            >
              <b-spinner
                v-if="loading"
                small
                class="mr-50"
              />
              Save changes
            </b-button>

          </b-col>
        </b-row>
      </b-form>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BButton, BCard, BCol, BForm, BFormGroup, BFormInput, BRow, BSpinner,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import gql from 'graphql-tag'
import { getUserData } from '@/auth/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BButton,
    BForm,
    // BImg,
    // BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BSpinner,
    // BAlert,
    BCard,
    // BCardText,
    // BMedia,
    // BMediaAside,
    // BMediaBody,
    // BLink,
  },
  directives: {
    Ripple,
  },
  props: {
    generalData: {
      type: Object,
      default: () => {
      },
    },
  },
  data() {
    return {
      loading: false,
      optionsLocal: '',
      profileFile: null,
      userEmail: JSON.parse(localStorage.getItem('userData')).userEmail,
    }
  },
  async beforeMount() {
    const result = await this.getUserData()
    const { data: { users_customuser: [userData] } } = result
    this.optionsLocal = userData
  },
  methods: {
    async resetForm() {
      const result = await this.getUserData()
      const { data: { users_customuser: [userData] } } = result
      this.optionsLocal = userData
    },
    updateUser() {
      this.loading = true
      this.$apollo.mutate({
        mutation: gql`mutation {
          update_users_customuser_by_pk(pk_columns: {id: ${getUserData().id}}, _set: {phone_number: "${this.optionsLocal.phone_number}", facebook_profile: "${this.optionsLocal.facebook_profile}", linkedin_profile: "${this.optionsLocal.linkedin_profile}"}) {
            id
          }
        }`,
        update: (store, { data: { update_users_customuser_by_pk } }) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: update_users_customuser_by_pk.id ? 'Profile updated successfully' : 'Failed to update',
              icon: update_users_customuser_by_pk.id ? 'CheckIcon' : 'XIcon',
              variant: update_users_customuser_by_pk.id ? 'success' : 'danger',
            },
          })
          this.loading = false
        },
      })
    },
    getUserData() {
      return this.$apollo.query({
        query: gql`
        {
          users_customuser(where: {email: {_eq: "${JSON.parse(localStorage.getItem('userData')).userEmail}"}}) {
            id
            first_name
            phone_number
            facebook_profile
            linkedin_profile
          }
        }`,
      })
    },
  },
}
</script>
