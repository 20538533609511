<template>
  <b-tabs
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
  >

    <!-- general tab -->
    <b-tab active>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">General</span>
      </template>

      <general />
    </b-tab>
    <!--/ general tab -->
    <!-- interests tab -->
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="HeartIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Interests</span>
      </template>

      <interests />
    </b-tab>
    <!--/ interests tab -->

  </b-tabs>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import { BTabs, BTab } from 'bootstrap-vue'

import General from './General.vue'
import Interests from './Interests.vue'

export default {
  components: {
    BTabs,
    BTab,
    General,
    Interests,
  },
  data() {
    return {
      options: {},
    }
  },
}
</script>
